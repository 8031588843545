import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3b82f6', // Your primary color
    },
    secondary: {
      main: '#ff4081', // Your secondary color
    },
    background: {
      default: 'white', // Default background color
    },
    text: {
      primary: '#1f2937', // Primary text color
      secondary: '#374151', // Secondary text color
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif', // Your preferred font family
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8, // Global border-radius for buttons
        },
      },
    },
  },
});

export default theme;
