import { useParams } from "react-router-dom";
import { useChat } from "../../Domain/chats";
import styled from "styled-components";
import React from "react";
import ChatMessages from "./ChatMessages";
import DateLabel from "../SharedComponents/DateLabel";
import ChatMessageInput from "./ChatMessageInput";

export const ChatDetails = () => {
  const chatId = useParams().chatId as string;
  const { data: chat, isLoading, error } = useChat(chatId);

  return (
    <ChatDetailsPage>
      <ChatDetailsContainer>
        {/*<ChatInfo>*/}
        {/*  <LeftColumn>*/}
        {/*    <ChatDetail><b>ChatID:</b> {chatId}</ChatDetail>*/}
        {/*    <ChatDetail><b>Username:</b> {chat?.username}</ChatDetail>*/}
        {/*    <ChatDetail><b>Full Name:</b> {chat?.fullName}</ChatDetail>*/}
        {/*    <ChatDetail><b>Last Message:</b> <DateLabel timestamp={chat?.lastMessageTime}/></ChatDetail>*/}
        {/*  </LeftColumn>*/}
        {/*  <RightColumn>*/}
        {/*    <ChatDetail><b>Active:</b> {chat?.active ? 'Yes' : 'No'}</ChatDetail>*/}
        {/*    <ChatDetail><b>Total Tokens:</b> {chat?.total_tokens}</ChatDetail>*/}
        {/*    <ChatDetail><b>Chat Status:</b> {chat?.chatStatus}</ChatDetail>*/}
        {/*  </RightColumn>*/}
        {/*</ChatInfo>*/}
        <ChatMessages chatId={chatId}/>
      </ChatDetailsContainer>

      <ChatMessageInput chatId={chatId}/>
    </ChatDetailsPage>
  );
}

const ChatDetailsPage = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: calc(100% - 10px);
  padding: 16px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 320px; 
  max-width: 768px; 
`;

const ChatDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
`;

const ChatInfo = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;


const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 16px;
  }
`;

const LeftColumn = styled(Column)``;
const RightColumn = styled(Column)``;

const ChatDetail = styled.div`
  margin-bottom: 8px;
  font-size: 1rem;

  & b {
    color: #1f2937;
  }
`;

export default ChatDetails;
