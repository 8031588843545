// src/components/ChatMessageInput.tsx
import React, { useState } from 'react';
import styled from 'styled-components';
import { TextField, Button } from '@mui/material';
import { usePostChatMessage } from "../../Domain/chats";

const ChatMessageInput = ({ chatId }: { chatId: string }) => {
  const [message, setMessage] = useState('');

  const { mutate: postMessage, isPending, error } = usePostChatMessage();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleSend = async () => {
    if (message.trim()) {
      console.log('Sending message:', message);

      try {
        const res = postMessage({ chatId, message }, {
          onSuccess: () => {
            setMessage('');
          }
        });
      } catch (err) {
        console.error('Error sending message:', err);
      }
    }
  };

  return (
    <InputContainer>
      {error && <div>Error: {error?.message}</div>}
      <TextField
        value={message}
        onChange={handleChange}
        placeholder="Type a message..."
        variant="outlined"
        multiline
        // rows={1}
        minRows={1} // Starts with 1 row and expands as needed
        maxRows={8} // Maximum of 4 rows
        fullWidth
        InputProps={{
          endAdornment: (
            <SendButton
              variant="contained"
              color="primary"
              disabled={isPending || !message.trim()}
              onClick={handleSend}>
              Send
            </SendButton>
          ),
        }}
      />
    </InputContainer>
  );
};

export default ChatMessageInput;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 8px;
  //padding: 8px;
  background-color: ${({ theme }) => theme.palette.background.default};
`;

const SendButton = styled(Button)`
  margin-left: 8px;
`;