// LoginPage.tsx
import React, { useState } from 'react';
import { useSignIn, useSignUp } from '@clerk/clerk-react';
import styled from 'styled-components';

const LoginPage: React.FC = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [error, setError] = useState('');

  const { isLoaded: isSignInLoaded, signIn, setActive: setSignInActive } = useSignIn();
  const { isLoaded: isSignUpLoaded, signUp, setActive: setSignUpActive } = useSignUp();

  if (!isSignInLoaded || !isSignUpLoaded) {
    return <div>Loading...</div>;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    try {
      if (isSignUp) {
        const result = await signUp.create({
          firstName,
          lastName,
          emailAddress: email,
          password,
        });
        if (result.status === "complete") {
          await setSignUpActive({ session: result.createdSessionId });
        } else {
          // Handle incomplete signup (e.g., email verification needed)
          console.log("Signup was not completed:", result);
        }
      } else {
        const result = await signIn.create({
          identifier: email,
          password,
        });
        if (result.status === "complete") {
          await setSignInActive({ session: result.createdSessionId });
        } else {
          // Handle 2FA or other factors if needed
          console.log("Additional authentication steps needed:", result);
        }
      }
    } catch (err: any) {
      console.error('Error:', err);
      setError(err.message || 'An error occurred.');
    }
  };

  return (
    <FormContainer>
      <Form onSubmit={handleSubmit}>
        <h2>{isSignUp ? 'Sign Up' : 'Sign In'}</h2>
        {isSignUp && (
          <>
            <Input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First Name"
              required
            />
            <Input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last Name"
              required
            />
          </>
        )}
        <Input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <Input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <Button type="submit">{isSignUp ? 'Sign Up' : 'Sign In'}</Button>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Form>
      {/*<ToggleButton onClick={() => setIsSignUp(!isSignUp)}>*/}
      {/*  {isSignUp ? 'Already have an account? Sign In' : 'Need an account? Sign Up'}*/}
      {/*</ToggleButton>*/}
    </FormContainer>
  );
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 300px;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 0.5rem;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #2563eb;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
`;

const ToggleButton = styled.button`
  margin-top: 1rem;
  background: none;
  border: none;
  color: #3b82f6;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export default LoginPage;