import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import * as API from './API';
import { Chat, ChatMessage } from './API';
import * as Network from "../Infrastructure/Network";

// Hook to fetch chat details
export const useChat = (chatId: string) => {
  return useQuery<Chat>({
    queryKey: ['chats', chatId],
    queryFn: () => API.getChat(chatId),
  });
};

// Hook to fetch all chats
export const useChats = () => {
  return useQuery<Chat[], Error>({
    queryKey: ['chats'],
    queryFn: API.getChats,
  });
};

// Hook to fetch chat messages
export const useChatMessages = (chatId: string) => {
  return useQuery<ChatMessage[], Error>({
    queryKey: ['chatMessages', chatId],
    queryFn: () => API.getChatMessages(chatId),
    enabled: !!chatId,
    refetchOnWindowFocus: true,
  });
};

// Hook to update chat active status
export const useUpdateChatActive = () => {
  const queryClient = useQueryClient();

  return useMutation<Chat, Error, { chatId: string; active: boolean }>({
    mutationFn: ({ chatId, active }) => {
      const chatToUpdate: Chat = {
        chatId,
        active,
        username: '',
        fullName: '',
        lastMessageTime: Date.now(),
        chatStatus: '',
        total_tokens: 0,
      };
      return API.updateChat(chatToUpdate);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['chats'] });
      queryClient.invalidateQueries({ queryKey: ['chat', variables.chatId] });
    },
  });
};

export const useActivateAllChats = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, boolean>({
    mutationFn: (active: boolean) => Network.post('chats/activate-all', { active }),
    onSuccess: () => {
      // Invalidate and refetch chats query to update the UI
      queryClient.invalidateQueries({ queryKey: ['chats'] });
    },
  });
};

export const useInactiveChatsCount = () => {
  return useQuery<number, Error>({
    queryKey: ['inactiveChatsCount'],
    queryFn: API.getInactiveChatsCount,
  });
};


// Hook to post a new chat message
export const usePostChatMessage = () => {
  const queryClient = useQueryClient();

  return useMutation<ChatMessage, Error, { chatId: string; message: string }>({
    mutationFn: ({ chatId, message }) => API.postChatMessage(chatId, message),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['chatMessages', variables.chatId] });
    },
  });
};
