import { Chat } from "../../Domain/API";
import React, { useState } from "react";
import { useUpdateChatActive } from "../../Domain/chats";
import { formatDistanceToNow } from "date-fns";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

export const ChatListItem = (chat: Chat) => {
  const { chatId, username, fullName, lastMessageTime, chatStatus, total_tokens } = chat;
  const [active, setActive] = useState(chat.active);
  const { mutate: updateChatActive, error, isPending } = useUpdateChatActive();
  const selectedChatId = useParams().chatId as string;
  const isSelected = selectedChatId === chatId;

  const handleActiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newActive = e.target.checked;
    updateChatActive(
      {
        chatId,
        active: newActive,
      },
      {
        onSuccess: () => {
          setActive(newActive);
        },
      }
    );
  };

  const date = lastMessageTime && new Date(lastMessageTime);
  const relativeTime = date && formatDistanceToNow(date, { addSuffix: true });

  const inputTokenPrice = 0.01; // per 1000 tokens
  const outputTokenPrice = 0.03; // per 1000 tokens
  const averageTokenPrice = (inputTokenPrice + outputTokenPrice) / 2 / 1000;
  const price = total_tokens ? total_tokens * averageTokenPrice : 0;
  const roundedPrice = parseFloat(price.toFixed(2));

  return (
    <ChatItemContainer $isSelected={isSelected}>
      <ChatLink to={`/chats/${chatId}`}>
        <UserName>{fullName}</UserName>
        <TimeStamp>{relativeTime}</TimeStamp>
      </ChatLink>
      <ChatDetails>
        <ChatDetailItem>chatId: <DetailValue>{chatId}</DetailValue></ChatDetailItem>
        <ChatDetailItem>username: <DetailValue>{username}</DetailValue></ChatDetailItem>
        {chatStatus && <ChatDetailItem>status: <DetailValue>{chatStatus}</DetailValue></ChatDetailItem>}
        {total_tokens && (
          <ChatDetailItem>
            tokens: <DetailValue>{total_tokens} (${roundedPrice})</DetailValue>
          </ChatDetailItem>
        )}
        {isPending && <LoadingMessage>updating the chat...</LoadingMessage>}
        {error && <ErrorMessage>Error updating the chat {error?.message}</ErrorMessage>}
        {!isPending && (
          <ActiveStatusContainer>
            <StatusCheckbox
              type="checkbox"
              id={`switch-${chatId}`}
              checked={active}
              onChange={handleActiveChange}
            />
            <StatusLabel htmlFor={`switch-${chatId}`} $active={active}>
              {active ? 'Active' : 'Inactive'}
            </StatusLabel>
          </ActiveStatusContainer>
        )}
      </ChatDetails>
    </ChatItemContainer>
  );
}

const ChatItemContainer = styled.div<{ $isSelected: boolean }>`
  padding: 16px;
  background-color: ${props => (props.$isSelected ? '#a3d1ff' : 'white')};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  margin-bottom: 16px;
`;

const ChatLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  text-decoration: none;
`;

const UserName = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
  color: #1f2937;
`;

const TimeStamp = styled.div`
  font-size: 0.875rem;
  color: #6b7280;
`;

const ChatDetails = styled.div`
  color: #374151;
`;

const ChatDetailItem = styled.div`
  font-size: 0.875rem;
  margin-bottom: 4px;
`;

const DetailValue = styled.span`
  font-weight: 500;
`;

const LoadingMessage = styled.div`
  color: #3b82f6;
`;

const ErrorMessage = styled.div`
  color: #ef4444;
`;

const ActiveStatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StatusCheckbox = styled.input`
  height: 20px;
  width: 20px;
  text-color: #3b82f6;
  margin-right: 5px;
`;

const StatusLabel = styled.label<{ $active: boolean }>`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${props => (props.$active ? '#065f46' : '#b91c1c')};
`;

export default ChatListItem;
