import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalImage = styled.img`
  max-width: 90%;
  max-height: 90%;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  
  // semi-transparent white
  background-color: rgba(255, 255, 255, 0.5);
  color: black;
  border: 2px solid black;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  z-index: 1001;
`;

const MessageImg = styled.img`
  max-width: 100%;
  max-height: 200px;
  margin-top: 8px;
  cursor: pointer;
`;

const ImageModal = ({ src, isOpen, closeModal } : { src: string, isOpen: boolean, closeModal: () => void }) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [closeModal]);

  if (!isOpen) return null;

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  return (
    <ModalOverlay onClick={handleOverlayClick}>
      <ModalContent>
        <ModalImage src={src} />
        <CloseButton onClick={closeModal}>X</CloseButton>
      </ModalContent>
    </ModalOverlay>
  );
};

const MessagePhoto = ({ src } : { src?: string }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  if (!src) return null;

  return (
    <div>
      <MessageImg src={src} onClick={openModal} />
      <ImageModal src={src} isOpen={isOpen} closeModal={closeModal} />
    </div>
  );
}

export default MessagePhoto;
