import { useActivateAllChats, useChats, useInactiveChatsCount } from "../../Domain/chats";
import { ChatListItem } from "./ChatListItem";
import { useParams } from "react-router-dom";
import { useState } from "react";
import styled from "styled-components";
import { ChatDetails } from "./ChatDetails";
import { Button } from "@mui/material";

const ChatsPage = () => {
  const { data: chats, isLoading, error } = useChats();
  const { chatId } = useParams();
  const [showMoreChats, setShowMoreChats] = useState(false);

  const sortedChats = (chats ?? []).sort((a, b) => {
    if (!a.lastMessageTime && !b.lastMessageTime) {
      return 0;
    }
    if (!a.lastMessageTime) {
      return 1;
    }
    if (!b.lastMessageTime) {
      return -1;
    }
    const timeA = new Date(a.lastMessageTime).getTime();
    const timeB = new Date(b.lastMessageTime).getTime();
    return timeB - timeA;
  });

  const showMore = () => setShowMoreChats(true);
  const chatsList = showMoreChats ? sortedChats : sortedChats.slice(0, 30);

  const { data: inactiveCount } = useInactiveChatsCount();

  const activateAllChatsMutation = useActivateAllChats();

  const onActivateClick = async () => {
    await activateAllChatsMutation.mutate(true);
  }

  return (
    <ChatsPageContainer>
      {isLoading && <LoadingMessage>Loading...</LoadingMessage>}
      {error && <ErrorMessage>{error?.message}</ErrorMessage>}
      <ChatsContainer>
        <ChatsListContainer>
          <InactiveChatsContainer>
            <InactiveChats inactiveCount={inactiveCount}>
            Inactive chats: {inactiveCount}
              {/*Activating: {activatingAllChats ? 'Yes' : 'No'}*/}
          </InactiveChats>
            <Button
              onClick={onActivateClick}
              disabled={activateAllChatsMutation.isPending}
            >Activate</Button>
          </InactiveChatsContainer>
          {chatsList && chatsList.map(chat => <ChatListItem key={chat.chatId} {...chat} />)}
          {sortedChats.length > 30 && (
            <ShowMoreButton onClick={showMore}>Show More</ShowMoreButton>
          )}
        </ChatsListContainer>
        <ChatDetailsWrapper>
          {chatId && <ChatDetails/>}
        </ChatDetailsWrapper>
      </ChatsContainer>
    </ChatsPageContainer>
  );
}


const ChatsPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 8px;
    background-color: #bfdbfe;
    height: 100%;
    overflow: hidden;
`;

const ChatsContainer = styled.div`
    display: flex;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const ChatsListContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    width: 24rem;
    padding: 8px;
    overflow-y: auto;
    padding-right: 12px;
    padding-top: 0;
    margin-top: 8px;
`;

const InactiveChatsContainer = styled.div`
    padding: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const InactiveChats = styled.div<{ inactiveCount: number }>`
    color: ${props => props.inactiveCount > 0 ? 'red' : 'inherit'};
    //font-weight: ${props => props.inactiveCount > 0 ? 'bold' : 'normal'};
`;


const ShowMoreButton = styled.button`
    background-color: #3b82f6;
    color: white;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
`;

const ChatDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 8px;
    height: 100%;
`;

const LoadingMessage = styled.div`
    color: #3b82f6;
`;

const ErrorMessage = styled.div`
    color: #ef4444;
`;


export default ChatsPage;

